import { themeGet } from "@styled-system/theme-get";
import { isMobile } from "app/layout/responsiveness/mediaQueries";
import { AdaptiveDateFormat, Button, Modal } from "components";
import LightboxContainer from "components/LightboxContainer";
import find from "lodash/find";
import get from "lodash/get";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { IoSpeedometer } from "react-icons/io5";
import { MdDelete, MdFavorite, MdFavoriteBorder, MdRepeat, MdReply } from "react-icons/md";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import TwemojiParser from "react-twemoji";
import styled, { css } from "styled-components";
import twitter from "twitter-text";
import { isGoogleDriveUrl } from "utils";
import { USER_TYPES } from "utils/constants";
import { ifProp, marginHorizontal, marginVertical } from "utils/mixins";
import DisplayName from "../DisplayName";
import NewWeezModal from "../post/NewWeezModal";
import ProfileImage from "../ProfileImage";
import BoostWeezeModal from "./BoostWeezeModal";
import VideoPlayer from "./VideoPlayer";
import WeezConversationModal from "./WeezConversationModal";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ifProp(
    "showBorder",
    css`
      ${marginVertical("8px")};
      ${marginHorizontal("3px")};
      padding: 10px;
      border: 1px solid ${themeGet("colors.veryLightGrey")};
      border-radius: 14px;
    `
  )};
  ${ifProp(
    "displayCompact",
    css`
      font-size: 12px;
    `
  )};
`;

const UpperContainer = styled.div`
  display: flex;
`;
//50px (left side width) + 15px(left side margin) left padding
const LowerContainer = styled.div`
  padding-left: 65px;
`;

const LeftSide = styled.div`
  width: ${isMobile ? "40px" : "50px"};
  margin-top: ${isMobile ? "5px" : "10px"};
  margin-right: ${isMobile ? "5px" : "15px"};
`;

const DeleteWeez = styled.div`
  display: initial;
`;

const DeleteWeezModal = styled.div`
  display: initial;
`;
const DeleteWeezModalText = styled.div`
  position: absolute;
  top: 40%;
  left: 20%;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #777777;
  font-weight: bold;
`;
const DeleteWeezModalButton = styled.div`
  position: absolute;
  bottom: 3px;
  right: 15px;
  button {
    margin-left: 15px;
    margin-bottom: 10px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const WeezHeader = styled.div`
  margin-bottom: 5px;
`;

const WeezFooter = styled.div`
  display: flex;
  align-items: flex-start;
  ${marginVertical("5px")};
  margin-left: 3px;
`;

const FooterButton = styled(Button)`
  margin-right: 30px;
  color: ${themeGet("colors.grey")};

  &:hover {
    color: ${themeGet("colors.primary")};
  }

  svg {
    margin-right: 5px;
  }
`;

const StyledDisplayName = styled(DisplayName)`
  margin-right: 5px;
`;

const headerTextStyle = css`
  font-size: ${(props) => (props.compact ? "0.9rem" : "1rem")};
  font-weight: 400;
  color: ${themeGet("colors.grey")};
`;

const Username = styled.span`
  ${headerTextStyle};
  margin-right: 5px;
`;

const WeezDate = styled.span`
  ${headerTextStyle};

  &::before {
    content: "·";
    margin-right: 5px;
  }
`;

const WeezTextContainer = styled(TwemojiParser)`
  line-height: 1.6;
  white-space: pre-wrap;

  a {
    text-decoration: none;
    color: ${themeGet("colors.primary")};

    &:hover {
      text-decoration: underline;
      color: ${themeGet("colors.primary")};
    }
  }
`;

const UserLink = styled(Link)`
  text-decoration: none;

  &:hover span {
    color: ${themeGet("colors.primary")};
  }
`;

const SimpleLink = styled.a`
  text-decoration: none;
  text-decoration: underline;
  color: ${themeGet("colors.primary")};
  cursor: pointer;
`;

function isVideoLink(url) {
  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return "youtube";
  } else if (url.includes("dailymotion.com")) {
    // inclure également l'url raccourcie
    return "dailymotion"; // tronquer le "?playlist" (ou peut être tout ce qu'il y a après le "?")
  }
  return ReactPlayer.canPlay(url);
}

const modalsType = {
  DELETE_MODAL: "delete",
  REWEEZ_MODAL: "reweez",
  REPLY_MODAL: "reply",
  CONVERSATION_MODAL: "conversation",
  BOOST_MODAL: "boost",
};

@inject("authStore")
@observer
class WeezContent extends React.Component {
  tokens = null;
  video = null;

  @observable modalState = null;

  constructor(props) {
    super(props);
    if (!props.weez) {
      throw new Error("missing weez property");
    }
    this.tokens = this.tokenize(props.weez.text);
    this.textWithLinks = this.tokens.map(this.renderToken);
  }

  openModal = (modalName) =>
    action(() => {
      this.modalState = modalName;
    });

  @action("CLOSE_MODAL")
  closeModal = () => {
    this.modalState = null;
  };

  @action("DELETE_WEEZ")
  delete = () => {
    const { weez } = this.props;
    weez.delete();
    this.props.onDelete(weez.id);
  };

  renderToken = (token, i) => {
    const key = token.type;
    switch (key) {
      case "url": {
        const url = token.v;
        const videoPlayable = isVideoLink(url);
        if ((videoPlayable || isGoogleDriveUrl(url)) && !this.video) {
          this.video = {
            url,
          };
        }
        const absoluteUrl = url.startsWith("http") ? url : `https://${url}`;
        return (
          <a
            key={i}
            target="_blank"
            rel="noopener noreferrer"
            href={absoluteUrl}
            style={{ display: "none" }}>
            {url}
          </a>
        );
      }
      case "screenName": {
        const username = token.v;
        const userId = get(
          find(this.props.weez.mentions, { username: username.substring(1) }),
          "id"
        );
        return (
          <Link
            key={i}
            to={{
              pathname: `/${username.substring(1)}`,
              search: userId ? `?id=${userId}` : "",
            }}>
            {username}
          </Link>
        );
      }
      case "hashtag": {
        const hashtag = token.v;
        return (
          <Link key={i} to={`/hashtag/${hashtag.substring(1)}`}>
            {hashtag}
          </Link>
        );
      }
      case "text":
      default: {
        return token.v;
      }
    }
  };

  // TODO Polyfill Object.entries ? (compat IE 11)
  tokenize = (text) => {
    const entities = twitter.extractEntitiesWithIndices(text);
    const tokens = [];
    if (entities.length === 0) {
      // Text only
      tokens.push({ type: "text", v: text });
    } else {
      let startIndex = 0;
      Object.entries(entities).forEach(([key, value]) => {
        const type = Object.keys(value)[0];
        const { indices } = value;
        const textToken = { type: "text", v: text.slice(startIndex, indices[0]) };
        if (textToken.v && textToken.v.length > 0) {
          tokens.push(textToken);
        }
        tokens.push({ type, v: text.slice(indices[0], indices[1]) });
        startIndex = indices[1];
      });
      // Add "normal" text after last entity
      if (startIndex !== text.length) {
        tokens.push({ type: "text", v: text.slice(startIndex, text.length) });
      }
    }
    return tokens;
  };

  render() {
    const {
      authStore,
      weez,
      reweez,
      showAvatar = true,
      showButtons = true,
      showBorder = false,
      displayCompact = false,
      t,
    } = this.props;

    const { author } = weez;
    return (
      <ContentContainer showBorder={showBorder} displayCompact={displayCompact}>
        <UpperContainer>
          {showAvatar && (
            <LeftSide>
              <ProfileImage photo={author.photo} />
            </LeftSide>
          )}
          <MainContainer>
            <WeezHeader>
              <UserLink to={{ pathname: `/${author.username}`, search: `id=${author.id}` }}>
                <StyledDisplayName user={author} size={displayCompact ? "reduced" : "default"} />
                <Username reduced={displayCompact}>@{author.username}</Username>
              </UserLink>
              <WeezDate reduced={displayCompact}>
                <AdaptiveDateFormat isoDate={weez.createdOn} />
              </WeezDate>
            </WeezHeader>
            <WeezTextContainer options={{ ext: ".svg", folder: "svg" }}>
              {this.textWithLinks}
            </WeezTextContainer>
            {!displayCompact && weez.images && weez.images.length > 0 && (
              <LightboxContainer images={weez.images} />
            )}
            {!displayCompact &&
              !weez.image &&
              (this.video || (weez.videos && weez.videos.length > 0)) && (
                <VideoPlayer video={this.video || weez.videos[0]} />
              )}
            {reweez && !displayCompact && (
              <WeezContent
                weez={reweez}
                showBorder
                showAvatar={false}
                showButtons={false}
                displayCompact={displayCompact}
              />
            )}
            {showButtons && (
              <WeezFooter>
                <FooterButton
                  empty
                  title={t("weeze.buttons.answer")}
                  onClick={this.openModal(modalsType.REPLY_MODAL)}>
                  <MdReply size={22} />
                </FooterButton>
                <FooterButton
                  empty
                  title={t("weeze.buttons.reweeze")}
                  onClick={this.openModal(modalsType.REWEEZ_MODAL)}>
                  <MdRepeat size={22} />
                  {weez.reweezsCount}
                </FooterButton>
                <FooterButton empty onClick={weez.like} title={t("weeze.buttons.like")}>
                  {weez.liked ? <MdFavorite size={25} /> : <MdFavoriteBorder size={25} />}
                  {weez.likesCount}
                </FooterButton>

                {authStore.currentUser && authStore.currentUser.role === USER_TYPES.ANIMATOR && (
                  <DeleteWeez>
                    <FooterButton
                      empty
                      onClick={this.openModal(modalsType.DELETE_MODAL)}
                      title={t("weeze.buttons.delete")}>
                      <MdDelete size={22} />
                    </FooterButton>
                    <Modal
                      isOpen={this.modalState === modalsType.DELETE_MODAL}
                      onRequestClose={this.closeModal}
                      title={t("weeze.delete.title")}>
                      <DeleteWeezModal>
                        <DeleteWeezModalText>
                          <MdDelete size={30} />
                          {t("weeze.delete.message")}
                        </DeleteWeezModalText>
                        <DeleteWeezModalButton>
                          <Button onClick={this.delete}>{t("delete")}</Button>
                          <Button onClick={this.closeModal}>{t("cancel")}</Button>
                        </DeleteWeezModalButton>
                      </DeleteWeezModal>
                    </Modal>
                  </DeleteWeez>
                )}

                {authStore.currentUser.isAnimator && !author.fakeUser && !isMobileOnly && (
                  <FooterButton
                    empty
                    onClick={this.openModal(modalsType.BOOST_MODAL)}
                    title={t("weeze.buttons.boost")}>
                    <IoSpeedometer size={22} />
                  </FooterButton>
                )}
                <NewWeezModal
                  isOpen={[modalsType.REPLY_MODAL, modalsType.REWEEZ_MODAL].includes(
                    this.modalState
                  )}
                  mode={this.modalState}
                  weez={weez}
                  onRequestClose={this.closeModal}
                />
                <WeezConversationModal
                  isOpen={this.modalState === modalsType.CONVERSATION_MODAL}
                  onRequestClose={this.closeModal}
                  conversationId={weez.conversationId}
                />
                {authStore.currentUser.isAnimator && !author.fakeUser && (
                  <BoostWeezeModal
                    isOpen={this.modalState === modalsType.BOOST_MODAL}
                    onRequestClose={this.closeModal}
                    weezeId={weez.id}
                  />
                )}
              </WeezFooter>
            )}
          </MainContainer>
        </UpperContainer>
        {showButtons && weez.conversationId && (
          <LowerContainer>
            <div onClick={this.openModal(modalsType.CONVERSATION_MODAL)}>
              <SimpleLink>{t("weeze.buttos.conversation_show")}</SimpleLink>
            </div>
          </LowerContainer>
        )}
      </ContentContainer>
    );
  }
}

export default withTranslation()(WeezContent);
