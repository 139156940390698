import React, { Fragment } from "react";
import { autorun, observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import { fromPromise } from "mobx-utils";
import modalHoc from "react-modal-hoc";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { Input, LoadingContent, Modal, ErrorContent } from "components";
import { UserItem } from "app/common";
import withInfiniteScroll from "app/common/withInfiniteScroll";
import * as usersApi from "services/users";
import { Field, getInputFieldProps } from "utils/forms";
import { marginVertical, resetList } from "utils/mixins";
import { t } from "utils/i18n";
// import throttle from "lodash/throttle";

const Container = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 500px;
`;

const SearchResults = styled.ul`
  ${resetList};
  height: 400px;
  overflow-y: scroll;
`;

const SearchResultsTitle = styled.span`
  ${marginVertical("20px")};
  font-weight: 600;
  font-size: 1.1rem;
  color: ${themeGet("colors.grey")};
`;

@inject("fetchService", "usersStore")
@observer
class NewWeezModal extends React.Component {
  @observable query = new Field();

  @observable getUsersRequest = null;

  @observable page;

  @observable users = [];

  constructor(props) {
    super(props);
    autorun(
      () => {
        this.search(this.query.value);
      },
      { delay: 300 }
    );
  }

  @action("SEARCH")
  search = (terms) => {
    this.page = 0;
    this.getUsersRequest = fromPromise(
      this.props.fetchService.fetch(
        usersApi.searchUsersPaginated({ terms, roles: ["DUMMY"] }, this.page, 10)
      )
    );
    this.getUsersRequest.then(
      action((values) => {
        this.users = values.data.map((value) => this.props.usersStore.deserializeUser(value));
      })
    );
  };

  @action("SEARCH_LOAD_MORE")
  loadMoreResult = () => {
    const terms = this.query.value;
    this.page++;
    this.getUsersRequest = fromPromise(
      this.props.fetchService.fetch(
        usersApi.searchUsersPaginated({ terms, roles: ["DUMMY"] }, this.page)
      )
    );
    this.getUsersRequest.then(
      action((values) => {
        this.users.push(
          ...values.data.map((value) => this.props.usersStore.deserializeUser(value))
        );
      })
    );
  };

  handleResultItemClick = (user) => (evt) => {
    this.props.chooseUser(user);
  };

  render() {
    const { onRequestClose } = this.props;
    return (
      <Modal isOpen title={`${t("post.publish_as.title")}...`} onRequestClose={onRequestClose}>
        <Container>
          <Input
            {...getInputFieldProps(this.query)}
            placeholder={`${t("post.publish_as.search")}...`}
          />
          {this.users && (
            <Fragment>
              <SearchResultsTitle>
                {this.users.length + " " + t("found_user") + " "}
              </SearchResultsTitle>
              <SearchResultsInfiniteScroll
                loadMore={this.loadMoreResult}
                list={this.users}
                onItemClick={this.handleResultItemClick}
              />
            </Fragment>
          )}
          <div>
            {this.getUsersRequest &&
              this.getUsersRequest.case({
                pending: () => <LoadingContent label={t("search.loading")} />,
                rejected: (error) => <ErrorContent text={error.code} />,
                fulfilled: () => null,
              })}
          </div>
        </Container>
      </Modal>
    );
  }
}

const ResultList = ({ list, onItemClick }) => {
  return (
    <SearchResults>
      {list.map((user) => (
        <UserItem key={user.id} user={user} onClick={onItemClick(user)} />
      ))}
    </SearchResults>
  );
};

const SearchResultsInfiniteScroll = withInfiniteScroll(ResultList);

export default modalHoc(NewWeezModal);
