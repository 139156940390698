import {
  Button,
  DatepickerInput,
  ErrorText,
  FormSection,
  ImageDropzone,
  InputField,
  InputWrapper,
} from "components"
import { observer } from "mobx-react"
import React from "react"
import styled from "styled-components"
import { t } from "utils/i18n"
import ColorPickerInput from "components/form/ColorPickerInput"
import { FORM_MODE_CREATE } from "utils/constants"

const today = new Date()

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`

const StyledImageDropzone = styled(ImageDropzone)`
  align-self: center;
`

@observer
class ClientForm extends React.Component {
  handleClick = () => {
    this.setState((state) => ({ ...state, displayColorPicker: !state.displayColorPicker }))
  }

  render() {
    const { form, onSubmit, requestObject, mode = FORM_MODE_CREATE } = this.props
    return (
      <form autoComplete="off" onSubmit={onSubmit}>
        <FormSection title={t("customer_form_title")}>
          <StyledImageDropzone field={form.logo} />
        </FormSection>
        <FormSection title={t("general_info")}>
          <InputField
            label={t("lastname") + " " + t("required")}
            type="text"
            field={form.clientName}
            maxLength="50"
          />
          <InputField
            label={t("app_name") + " " + t("required")}
            type="text"
            field={form.applicationName}
            maxLength="50"
          />
          <InputField
            label={t("path") + " " + t("required")}
            type="text"
            field={form.clientPath}
            maxLength="50"
          />
          <ColorPickerInput label={t("color") + " " + t("required")} field={form.colorMain} />

          <InputWrapper label={t("end_date_contract")}>
            <DatepickerInput
              field={form.endDate}
              // date={mode === "edit" ? form.endDate.value : today}
            />
          </InputWrapper>
        </FormSection>
        {requestObject && requestObject.state === "rejected" && (
          <ErrorText>{t(requestObject.value.code)}</ErrorText>
        )}
        <ButtonContainer>
          <Button
            admin
            to="/admin/clients"
            disabled={requestObject && requestObject.state === "pending"}>
            {t("cancel")}
          </Button>
          <Button
            admin
            full
            type="submit"
            invalid={!form.isValid}
            loading={requestObject && requestObject.state === "pending"}>
            {mode === FORM_MODE_CREATE ? t("create_customer") : t("edit_customer")}
          </Button>
        </ButtonContainer>
      </form>
    )
  }
}

export default ClientForm
