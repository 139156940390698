import HomePage from "app/home";
import NotificationsPage from "app/notifications";
import ProfilePage from "app/profile";
import { AuthorizedRoute } from "components";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HashtagPage from "app/hashtag";
import HelpPage from "app/help";
import Disclaimer from "app/disclaimer";
import PrivacyPage from "app/admin/privacy";
import NewWeezModal from "app/common/post/NewWeezModal";
import Notifications from "./Notifications";
import MobileBottomMenu from "./menus/MobileBottomMenu";
import styled from "styled-components";
import SearchStoreProvider from "stores/SearchStoreProvider";
import MessagesRouter from "app/messages";
import SearchPageMobile from "app/search/SearchPage.mobile";
import ExplorePageMobile from "app/explore/ExplorePage.mobile";
import MainHeaderMobile from "./headers/MainHeader.mobile";

function MainLayoutMobile({
  viewRoles = [],
  redirectUrl,
  newWeezModalOpened = false,
  toggleNewWeezModal = () => null,
  user,
}) {
  return (
    <Container>
      <SearchStoreProvider>
        <Switch>
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path={["/"]}
            exact
            render={(props) => basicMobileLayout(props, user, HomePage)}
          />
          <Redirect to={redirectUrl} />
        </Switch>
      </SearchStoreProvider>
      <MobileBottomMenu />
      {/* <Notifications /> */}
      {/* <NewWeezModal isOpen={newWeezModalOpened} onRequestClose={toggleNewWeezModal} /> */}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100vh;
`;

const PageContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 100%;
  max-height: 100%;
  margin: 65px 0 50px;
  overflow-x: hidden;
  > div {
    width: 100%;
  }
`;

function basicMobileLayout(props, user, component) {
  const Component = component;
  return (
    <React.Fragment>
      <MainHeaderMobile user={user} photo={user.photo} />
      <PageContent>
        <Component {...props} />
      </PageContent>
    </React.Fragment>
  );
}

export default MainLayoutMobile;
export { PageContent };
