import { getServerUrl } from "utils/fetch";

export function getSequences(sortBy = "createdOn", direction = "desc") {
  return {
    url: getServerUrl("/admin/sequences", { sortby: sortBy, dir: direction }),
    method: "GET",
  };
}

export function getSequence(id) {
  return {
    url: getServerUrl(`/admin/sequences/${id}`),
    method: "GET",
  };
}

export function importSequence(file, metadata = {}) {
  return {
    url: getServerUrl("/admin/sequences/import"),
    type: "multipart",
    fields: {
      file,
      metadata: new Blob([JSON.stringify(metadata)], { type: "application/json" }),
    },
  };
}

export function downloadSequence(id) {
  return {
    url: getServerUrl(`/admin/sequences/${id}/export`),
    method: "GET",
    type: "download",
  };
}

export function removeSequence(id) {
  return {
    url: getServerUrl(`/admin/sequences/${id}`),
    method: "DELETE",
  };
}

export function updateSequenceStatus(id, body) {
  return {
    url: getServerUrl(`/admin/sequences/${id} `),
    method: "POST",
    body: JSON.stringify(body),
  };
}

export function sequenceAction(body, id) {
  return {
    url: getServerUrl(`/admin/sequences/${id}/status`),
    method: "POST",
    body: JSON.stringify(body),
  };
}
