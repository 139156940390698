import { inject, observer } from "mobx-react";
import React from "react";
import { USER_TYPES } from "utils/constants";
import MainLayoutDesktop from "./MainLayout.desktop";
import MainLayoutMobile from "./MainLayout.mobile";
import { BrowserView, MobileView } from "./responsiveness/mediaQueries";

const VIEW_ROLES = [USER_TYPES.ANIMATOR, USER_TYPES.USER];

@inject("authStore", "viewStore")
@observer
class MainLayout extends React.Component {
  render() {
    const { viewStore, authStore } = this.props;
    const user = authStore.currentUser;
    const profileUrl = `/${user.username}`;
    // const redirectUrl = user.role === USER_TYPES.SUPERADMIN ? "/admin" : profileUrl;
    const redirectUrl = "/admin";
    const { newWeezModalOpened, toggleNewWeezModal } = viewStore;
    return (
      <React.Fragment>
        <BrowserView>
          <MainLayoutDesktop
            redirectUrl={redirectUrl}
            viewRoles={VIEW_ROLES}
            newWeezModalOpened={newWeezModalOpened}
            toggleNewWeezModal={toggleNewWeezModal}
          />
        </BrowserView>
        <MobileView>
          <MainLayoutMobile
            user={user}
            redirectUrl={redirectUrl}
            viewRoles={VIEW_ROLES}
            newWeezModalOpened={newWeezModalOpened}
            toggleNewWeezModal={toggleNewWeezModal}
          />
        </MobileView>
      </React.Fragment>
    );
  }
}

export default MainLayout;
