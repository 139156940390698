import loginBackground from "assets/login_background.jpg";
import logoTechlyde from "assets/logo_techlyde_white.svg";
import { Button, ErrorText, Input } from "components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { cancelEvent } from "utils";
import {
  Field,
  fieldsAggregatorBuilder,
  getInputFieldProps,
  isRequired,
  forbiddenChar,
} from "utils/forms";
import { t } from "utils/i18n";
import Notifications from "app/layout/Notifications";
import { withTranslation } from "react-i18next";

const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  background-image: url(${loginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const LogoImg = styled.img`
  width: 300px;
  position: absolute;
  right: 50px;
  top: 50px;
`;

const ContentContainer = styled.div`
  margin-top: 150px;
  /* margin-left: 50px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* background-color: #ffffffaa; */

  h1 {
    color: ${themeGet("colors.white")};
    font-size: 2rem;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  width: 250px;
`;

const SubmitButton = styled(Button)`
  margin-top: 15px;
  /* align-self: flex-end; */
`;

/**
 * Notes:
 * Fields are disabled state is not dealt by the fieldsAggregatorBuilder but directly in the fields
 */
@inject("authStore", "routingStore", "viewStore")
@observer
class LoginPage extends React.Component {
  form = fieldsAggregatorBuilder({
    fields: {
      username: new Field({
        validate: [
          isRequired,
          forbiddenChar(" ", "Login", "Le login ne doit pas contenir d'espace! "),
        ],
        label: t("username"),
      }),
      password: new Field({
        validate: [
          isRequired,
          forbiddenChar(" ", "Password", "Le mot de passe ne doit pas contenir d'espace"),
        ],
        label: t("password"),
      }),
    },
  });

  @observable loginRequest = null;
  @observable loginDisabled = false;

  @action("LOGIN")
  onSubmit = cancelEvent((evt) => {
    const { routingStore, authStore } = this.props;
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      this.loginRequest = fromPromise(
        authStore.login(
          this.form.username.value.trim(),
          this.form.password.value.trim(),
          routingStore.clientPath,
          routingStore.clonePath
        )
      );
      this.loginRequest
        .then(() => {
          routingStore.push("/");
        })
        .catch((e) => null);
    }
  });

  validateClientPath = async () => {
    const { routingStore, authStore, viewStore } = this.props;
    if (!routingStore.basename) {
      // no path means we are accessing the admin
      return;
    }
    const isClientPathValid = await authStore.validateClientPath(
      routingStore.basename,
      routingStore.isClone
    );
    if (!isClientPathValid) {
      this.toggleLoginDisabled(true);
      viewStore.pushNotification(
        `Le chemin "${routingStore.basename}" n'existe pas`,
        "error",
        5000
      );
    } else if (this.loginDisabled) {
      this.toggleLoginDisabled(false);
    }
  };

  @action
  toggleLoginDisabled(isDisabled) {
    this.loginDisabled = isDisabled;
  }

  componentDidMount() {
    this.validateClientPath();
  }

  render() {
    const { authStore } = this.props;
    const { isConnected } = authStore;

    if (isConnected) {
      return <Redirect to="/" />;
    }

    return (
      <BackgroundContainer>
        <Helmet>
          <title>{t("connection_title")}</title>
        </Helmet>
        <LogoImg src={logoTechlyde} />
        <ContentContainer>
          <h1>{t("login_page_title")}</h1>
          <LoginForm autocomplete="on" onSubmit={this.onSubmit}>
            <StyledInput
              white
              type="text"
              placeholder={t("username")}
              name="username"
              {...getInputFieldProps(this.form.username)}
              autocomplete="username"
              disabled={this.loginDisabled}
            />
            <StyledInput
              white
              placeholder={t("password")}
              type="password"
              name="password"
              {...getInputFieldProps(this.form.password)}
              autocomplete="current-password"
              disabled={this.loginDisabled}
            />
            {this.form.password.errorMessage && (
              <ErrorText>{this.form.password.errorMessage}</ErrorText>
            )}
            {this.form.username.errorMessage && (
              <ErrorText>{this.form.username.errorMessage}</ErrorText>
            )}
            {this.loginRequest && this.loginRequest.state === "rejected" && (
              <ErrorText>{t(this.loginRequest.value.code)}</ErrorText>
            )}
            <SubmitButton
              secondary
              type="submit"
              invalid={!this.form.isValid}
              disabled={this.loginDisabled}>
              {t("connection_button")}
            </SubmitButton>
          </LoginForm>
        </ContentContainer>
        <Notifications />
      </BackgroundContainer>
    );
  }
}

export default withTranslation()(LoginPage);
