import AdminPage from "app/admin";
// import NewWeezModal from "app/common/post/NewWeezModal";
import { AuthorizedRoute } from "components";
import React from "react";

import { themeGet } from "@styled-system/theme-get";
import { Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import { USER_TYPES } from "utils/constants";
// import Notifications from "./Notifications";
// import Header from "./headers/Header";

const Container = styled.div`
  background-color: ${themeGet("colors.backgroundGrey")};
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const PageContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

function MainLayoutDesktop({
  viewRoles = [],
  redirectUrl,
  newWeezModalOpened = false,
  toggleNewWeezModal = () => null,
}) {
  return (
    <Container>
      {/* <Header /> */}
      <PageContent>
        <Switch>
          <AuthorizedRoute
            roles={[USER_TYPES.SUPERADMIN, USER_TYPES.ANIMATOR]}
            redirectTo={redirectUrl}
            path="/admin"
            component={AdminPage}
          />
          <AuthorizedRoute
            roles={[USER_TYPES.SUPERADMIN, USER_TYPES.ANIMATOR]}
            redirectTo={redirectUrl}
            path={["/"]}
            exact
            component={AdminPage}
          />
          <Redirect to={redirectUrl} />
        </Switch>
      </PageContent>
      {/* <Notifications />
      <NewWeezModal isOpen={newWeezModalOpened} onRequestClose={toggleNewWeezModal} /> */}
    </Container>
  );
}

export default MainLayoutDesktop;
